@import '../theme-bootstrap';

.hero {
  &__content {
    height: 100%;
    justify-content: space-between;
    .content-block__content {
      height: auto;
    }
  }
  &__content-wrapper {
    position: relative;
    height: auto;
  }
  &__wrapper {
    height: auto;
    display: flex;
    flex-direction: column;
    &--content-flip {
      flex-direction: column-reverse;
    }
  }
  &__media-wrapper {
    height: auto;
    .hero__media {
      height: 100%;
      .mantle-media-asset {
        height: 100%;
        img {
          min-height: 100%;
        }
      }
    }
    &--video {
      .content-over-media__wrapper {
        z-index: 3;
      }
    }
  }
  &__text-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
  }
  &__content-background {
    z-index: -1;
    img,
    picture,
    video {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  &__content-over-media {
    pointer-events: none;
    transform: translate3d(0, 0, 0);
    .video-active & {
      display: none;
    }
    .align-#{$rdirection} {
      .custom-text {
        margin-#{$rdirection}: -0.09ex;
      }
    }
    .align-#{$ldirection} {
      .custom-text {
        margin-#{$ldirection}: -0.15ex;
      }
    }
  }
  .content-over-media__wrapper {
    pointer-events: all;
  }
  img,
  picture,
  video {
    max-width: none;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .pc-hidden {
    display: block;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  .mobile-flex-hidden {
    display: none;
    @include breakpoint($landscape-up) {
      display: flex;
    }
  }
  .text-cta {
    background: none;
    padding: 0;
    border: none;
    min-width: auto;
  }
  p {
    font-size: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    margin-bottom: inherit;
  }
  // helpers
  .text-color {
    color: $off-black;
    &--white {
      color: $color-white;
    }
    &--black {
      color: $color-black;
    }
    &--light-grey {
      color: $color-light-gray;
    }
    &--mid-grey {
      color: $color-mid-gray;
    }
    &--grey {
      color: $color-mid-gray;
    }
    &--dark-grey {
      color: $color-darker-gray;
    }
    &--green {
      color: $color-green;
    }
    &--light-green {
      color: $color-light-green;
    }
    &--ivory {
      color: $color-ivory;
    }
    &--red {
      color: $color-red;
    }
  }
  // overrides
  div.content-block {
    &__line {
      padding: 0;
      &--eyebrow {
        &.margin-px-bottom-default {
          margin-bottom: 20px;
        }
        .style--bare {
          @include text-style--eyebrow;
        }
      }
      &--header {
        &.margin-px-bottom-default {
          margin-bottom: 20px;
        }
        .style--bare {
          @include text-style--large-title-tight;
        }
      }
      &--content {
        .style--bare {
          @include text-style--body;
        }
        &-secondary {
          .style--bare {
            @include text-style--subcopy;
          }
        }
      }
      &--disclaimer {
        .style--bare {
          @include text-style--subcopy;
        }
      }
    }
    &__link {
      padding: 20px 0 0;
      .button {
        &:not([class*='content-block-button--']) {
          @include content-block-btn-primary;
        }
      }
    }
    b,
    strong {
      font-family: $font--futura-demi;
    }
  }
  &.padding-px-150 {
    padding: 150px;
  }
  &.padding-px-200 {
    padding: 200px;
  }
  &.padding-px-300 {
    padding: 300px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  .content-block__line {
    &.align-#{$ldirection} {
      margin-#{$ldirection}: 0;
      margin-#{$rdirection}: auto;
    }
    &.align-#{$rdirection} {
      margin-#{$rdirection}: 0;
      margin-#{$ldirection}: auto;
    }
    &.align-center {
      margin-#{$rdirection}: auto;
      margin-#{$ldirection}: auto;
    }
  }
}
